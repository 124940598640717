
<template>
  <van-swipe
    class="my-swipe"
    :autoplay="3000"
    indicator-color="#F75D22"
  >
    <van-swipe-item
      v-for="(item, index) in list"
      :key="index"
    >
      <img
        :src="item.picUrl"
        alt=""
        @click="goTo(item.linkUrl)"
      >
    </van-swipe-item>
  </van-swipe>
</template>
<script>
// banner图轮播组件
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    goTo(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang='scss' scoped>
.my-swipe {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
