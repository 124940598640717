<template>
  <div>

    <header class="home-header wrap" :class="{ 'active': headerScroll }">
      <div class="nav-address" v-if="address">
        <van-icon name="location" color="#F6F6F6" />
        <span>{{ address }}</span>
      </div>
      <div class="header-search">
        <van-icon name="search" size='0.5rem' />
        <router-link tag="span" class="search-title" to="/seach">积分当钱花</router-link>
      </div>
      <!-- <router-link class="login" tag="span" to="./login" v-if="!isLogin">登录</router-link>
      <router-link class="login" tag="span" to="./user" v-else>
        <van-icon name="manager-o" />
      </router-link> -->
    </header>
    <div class="swiper-cen">
      <swiper-view :list="swiperList"></swiper-view>

    </div>

    <div class="category-list">
      <div v-for="item in categoryList" v-bind:key="item.categoryId" @click="tips(item)">
        <img :src="item.icon">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <section class="section-placard">
      <div class="palcard-cen" @click="details()">
        <div class="placard-left">
          <van-icon name="volume-o" size='0.5rem' :color="variables.textColor" style="vertical-align: middle;" />
          <span :style="{ color: variables.textColor }">商城公告</span>
        </div>
        <div class="placard-right">
          <marquee align="left" style="font-size:14px;" :style="{ color: variables.textColor }">
            端午放假通知:
            尊敬的各代理渠道，我司放假时间为6月22日-6月24日，6月25日恢复上班
            放假期间平台关闭提现功能，可以正常做单，有任何问题请致电对接负责人

            感谢合作伙伴一直以来的支持，在此肥猫商城全体员工提前️祝大家端午节快乐，愿我们的合作越来越好，事业更上一层楼。
            2023年6月20日</marquee>
        </div>
      </div>
    </section>
    <section class="section section-produst">
      <div class="produst-list" v-for="(item, index) in products" :key='index'>
        <div class="produst-item">
          <p class="p1">{{ item.name }}</p>
          <p class="new-text p2" :class="'text' + index">{{ item.title }}</p>
          <p class="p3">
            <span class="pro-back" :class="'back' + index" @click="onGoBack(item)">
              {{ item.dec }}
              <van-icon name="arrow" />
            </span>
          </p>
          <div class="produst-img">
            <van-image width="80" height="90" :src='item.img' />
          </div>
        </div>
      </div>
    </section>
    <div class="section section-new ">

      <div class="new-list" v-for="(item, index) in newList" :key='index'>
        <div class="new-item" @click="goClick(item)">
          <div class="new-tit">
            <div class="new-tit1">{{ item.name }}</div>
            <div class="new-back" :class="'back' + index" @click.stop="goClick(item)">GO
              <van-icon name="arrow" />
            </div>
          </div>
          <div class="new-text " :class="'text' + index">{{ item.title }}</div>
          <div class="new-img">
            <van-image width="60" height="80" :src='item.img' />

          </div>
        </div>
      </div>

    </div>
    <section class="section section-new2">
      <div class="new2-cen">
        <div class="new2-l">
          <p class="new2-t1">每日上新</p>
          <p class="new2-t2">定期更新</p>
          <img src="../../assets/image/home/img3.png" @click="onImg()">
        </div>
        <div class="new2-r">
          <div class="new-c">
            <div class="new-c-l">
              <div class="new2-item">
                <p class="new2-t1">爆款合集</p>
                <p class="new2-t3">想要的商品都在这里</p>
              </div>
              <div class="new2-img">
                <img src="../../assets/image/home/image1.png" @click="onImg()">
              </div>
            </div>
            <div class="new-c-r">
              <div class="new2-item">
                <p class="new2-t1">爆款合集</p>
                <img src="../../assets/image/home/img4.png" @click="onImg()">
              </div>
              <div class="new2-item">
                <p class="new2-t1">热兑推荐</p>
                <img src="../../assets/image/home/img5.png" @click="onImg()">
              </div>
            </div>
          </div>
          <div class="new-m"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Toast } from "vant";
import SwiperView from "../../components/swiper-view";
import page from "../../utils/data.js";
import variables from "@/styles/variables.scss";
import utils from "../../utils/index";
// import BMap from "BMap";
export default {
  name: "home",
  components: {
    SwiperView,
  },
  data() {
    return {
      swiperList: page.banners, // 轮播图列表
      isLogin: false, // 是否已登录
      headerScroll: false, // 滚动透明判断
      hots: [],
      newGoodses: [],
      recommends: [],
      categoryList: page.categories,
      loading: true,
      newList: [
        {
          id: 0,
          type: "new",
          name: "每日上新",
          title: "热兑商品任你选",
          // img: "https://cdn.cnbj0.fds.api.mi-img.com/b2c-shopapi-pms/pms_1575881724.64226888.jpg",
          img: require("../../assets/image/home/img2.jpg"),
        },
        {
          id: 1,
          type: "isHot",
          name: "天天特价",
          title: "惊喜折扣兑",
          img: require("../../assets/image/home/image1.png"),
          // img: "https://cdn.it120.cc/apifactory/2019/07/11/e9fb59ae-7e66-427d-8d47-642c3b733a0b.jpg",
        },
      ],
      // address: "浙江省宁波市鄞州区",
      address: "",
      products: [
        {
          id: 1,
          name: "手机充值",
          title: "手机话费随心充",
          dec: "立即充值",
          img: require("../../assets/image/home/tab1.png"),
          path: "https://lt.591wsh.com/pages/gooddetail/index?id=45327560609538048",
        },
        {
          id: 2,
          name: "积分兑换",
          title: "无限好礼等你领",
          dec: "立即兑换",
          img: require("../../assets/image/home/tab2.png"),
          path: "/redeem",
        },
      ],
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  created() {
    // this.getAddress();
  },
  methods: {
    // getAddress() {
    //   let that = this;
    //   var geolocation = new BMap.Geolocation();
    //   geolocation.getCurrentPosition(function (r) {
    //     if (this.getStatus() === BMAP_STATUS_SUCCESS) {
    //       // let map = r.address;
    //       // var lat = map.lat; //当前经度
    //       // var lng = map.lng; //当前纬度

    //       // var province = map.province; //返回当前省份
    //       // var city = map.city; //返回当前城市
    //       // var district = map.district;
    //       // console.log(r);
    //       that.getCity(r.point);
    //     }
    //   });
    // },

    details() {
      this.$router.push("/noticeDet");
    },
    // getCity(point) {
    //   let that = this;
    //   var point = new BMap.Point(point.lng, point.lat); //用所定位的经纬度查找所在地省市街道等信息

    //   var gc = new BMap.Geocoder();
    //   gc.getLocation(point, function (rs) {
    //     // var addComp = rs.addressComponents;
    //     that.address = rs.address;
    //     utils.setStore("address", rs.address);
    //   });
    // },
    tips(item) {
      if (item.type === "1") {
        // 积分兑换
        this.$router.push("/redeem");
      } else {
        window.location.href = item.path;
        // window.open(item.path);
      }
      // else if (item.type === "2") {
      //   //移动花卡
      //   window.location.href =
      //     "https://cmhd.cscmgg.com/hd_pg/yd/agency/cm/pg001?c_code=OyyMdx-kghDwz";
      // } else if (item.type === "3") {
      //   //话费充值
      //   this.$router.push("/recharge");
      // } else {
      //   Toast("开发中.....");
      // }
    },
    goClick(item) {
      if (item.type === "isHot") {
        this.$router.push({
          name: "mall",
          params: {
            id: item.id,
          },
        });
      } else {
        this.$router.push("/mall");
      }

      this.$store.commit("menu/bottomId", 1);
    },
    onImg() {
      //https://m.jf.10086.cn/#/pages/index/index
      window.location.href = "https://m.jf.10086.cn/#/pages/index/index";
      // this.$router.push("/mall");
      // this.$store.commit("menu/bottomId", 1);
    },
    onGoBack(item) {
      if (item.id === 1) {
        window.location.href = item.path;
      } else {
        this.$router.push(item.path);
      }
    },
  },
};
</script>

<style lang="scss" scoped >
@import "../../styles/variables.scss";

.home-header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;

  // height: 50px;
  // line-height: 50px;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 15px;
  color: #fff;
  z-index: 10000;
  background: $background-color;

  .nav-address {
    min-height: 20px;
    padding-top: 10px;
  }

  .nbmenu2 {
    color: $textColor;
  }

  &.active {
    background: $textColor;

    .nbmenu2 {
      color: #fff;
    }

    .login {
      color: #fff;
    }
  }

  .header-search {
    display: flex;
    width: 94%;
    height: 20px;
    line-height: 20px;
    margin: 10px 0;
    padding: 5px 10px;
    color: #232326;
    float: left;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 20px;

    .search-title {
      width: 94%;
      font-size: 14px;
      color: #666;
      line-height: 21px;
      margin-left: 10px;
    }
  }

  .login {
    color: #fff;
    line-height: 52px;

    .van-icon-manager-o {
      font-size: 20px;
      vertical-align: -3px;
    }
  }
}

.swiper-cen {
  // margin-top: 75px;
  margin-top: 50px;
  padding: 10px 15px;
  position: relative;

  &::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    height: 120px;
    width: 100%;
    background: $background-color;
  }
}

.category-list {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 13px;
  font-size: 12px;

  div {
    display: flex;
    flex-direction: column;
    width: 25%;
    text-align: center;

    img {
      width: 64px;
      height: 64px;
      margin: auto;
      // margin: 13px auto 8px auto;
    }
  }
}

.good {
  .good-header {
    background: #f9f9f9;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #1baeae;
    font-size: 16px;
    font-weight: 500;
  }

  .good-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .good-item {
      box-sizing: border-box;
      width: 50%;
      border-bottom: 1px solid #e9e9e9;
      padding: 10px 10px;

      img {
        display: block;
        width: 120px;
        margin: 0 auto;
      }

      .good-desc {
        text-align: center;
        font-size: 14px;
        padding: 10px 0;

        .title {
          color: #222333;
        }

        .price {
          color: #1baeae;
        }
      }

      &:nth-child(2n + 1) {
        border-right: 1px solid #e9e9e9;
      }
    }
  }
}

.floor-list {
  width: 100%;
  padding-bottom: 50px;

  .floor-head {
    width: 100%;
    height: 40px;
    background: #f6f6f6;
  }

  .floor-content {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    width: 100%;

    .floor-category {
      width: 50%;
      padding: 10px;
      border-right: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;

      &:nth-child(2n) {
        border-right: none;
      }

      p {
        font-size: 17px;
        color: #333;

        &:nth-child(2) {
          padding: 5px 0;
          font-size: 13px;
          color: #1baeae;
        }
      }

      .floor-products {
        width: 100%;

        img {
          width: 65px;
          height: 65px;
        }
      }
    }
  }
}

.section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.section-new,
.section-produst {
  padding: 0 6px;
  font-size: 12px;
  margin-bottom: 10px;
}

.new-list,
.produst-list {
  width: 50%;
  box-sizing: border-box;
  padding: 0 6px;
  position: relative;
}

.new-item {
  background-color: #fff;
  border-radius: 9px;
  padding: 10px;
}

.produst-item {
  background-color: #fff;
  border-radius: 9px;
  padding: 16px 16px 24px 10px;
  font-size: 12px;

  .p1 {
    color: #5e5e5e;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .p3 {
    text-align: left;
    margin-top: 30px;
  }

  .pro-back {
    background: #ffffff;
    text-align: center;
    border-radius: 0.21333rem;
    cursor: pointer;
    padding: 2px 6px;
    font-size: 12px;
  }
}

.produst-img {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.new-tit {
  display: flex;
}

.new-tit1 {
  font-size: 14px;
  color: #5e5e5e;
  flex: 1;
}

.text0 {
  color: #e4c980;
}

.text1 {
  color: #96ccec;
}

.new-img {
  margin-top: 10px;
  text-align: center;
  // height: 70px;
}

.new-back {
  width: 40px;
  height: 16px;
  line-height: 16px;
  background: #ffffff;
  text-align: center;
  border-radius: 8px;
  float: right;
}

.back0 {
  color: #e4c980;
  box-shadow: 0px 2px 4px 0px rgba(229, 197, 125, 0.53);
}

.back1 {
  color: #96ccec;
  box-shadow: 0px 2px 4px 0px #89bdeb;
}

.section-placard {
  padding: 12px;

  .palcard-cen {
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;
    align-items: center;

    // flex-direction: column;
    .placard-left {
      width: 84px;
      border-right: 1px solid #70707030;

      span {
        font-size: 12px;
        font-weight: 600;

        margin-left: 5px;
      }
    }

    .placard-right {
      flex: 1;
      padding: 0 10px;
    }
  }
}

.section-new2 {
  padding: 12px;
  padding-bottom: 60px;

  .new2-cen {
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
  }

  .new2-l {
    width: 35%;
  }

  .new2-r {
    flex: 1;

    border-left: 1px solid rgba(112, 112, 112, 0.2);
  }

  .new-c>div {
    display: flex;
  }

  .new-c-l>.new2-item {
    width: 70%;
  }

  .new2-img {
    flex: 1;
  }

  .new-c-r>div {
    flex: 1;
  }

  .new2-img>img {
    width: 100%;
  }

  .new2-t1 {
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 500;
  }

  .new2-t2 {
    font-weight: normal;
    color: #e4c980;
    font-size: 12px;
    padding: 8px 0 16px 0;
  }

  .new2-t3 {
    font-weight: 500;
    color: #bab5f4;
    font-size: 12px;
    padding-top: 8px;
  }

  .new-c-l {
    padding-left: 16px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  }

  .new-c-r {
    padding-top: 10px;
    text-align: center;
  }

  .new-c-r>div {
    padding-left: 16px;
  }

  .new-c-r>div:first-child {
    border-right: 1px solid rgba(112, 112, 112, 0.2);
  }

  .new-c-r .new2-t1 {
    padding-bottom: 8px;
    text-align: left;
  }
}
</style>